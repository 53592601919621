import React from "react";
import enrolmentBottomImage from "../../assets/enrolment/bottomEnrolment.jpg";
import SideBar from "../master/SideBar";
import ContentWrapper from "../master/ContentWrapper";
import {EnrolmentSidebarLink} from "../const";
import PageTitle from "../master/PageTittle";

const ScheduleOfFeesContent = () => (
    <div className="container">
        <ContentWrapper sidebar={true} narrow={true}>
            <div className={"content"}>
                <PageTitle title="School Fees"/>

                <PageTitle title="Enrolment Fee" variant="h3"/>

                <p>
                    A non-refundable enrolment fee of $250 payable at the time of
                    submitting an Application for Enrolment to the College.
                </p>
                <p>
                    Fee for students coming from a Maronite Sisters of the Holy Family
                    Pre-School is $150.
                </p>

                <PageTitle title="College Fees" variant="h3"/>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Year Group</th>
                        <th scope="col">Kindergarten
                            to Yr 6</th>
                        <th scope="col">Yr 7 – Yr 8</th>
                        <th scope="col">Yr 9 – Yr 10</th>
                        <th scope="col">Yr 11 – Yr 12</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th scope="row">Tuition Fees</th>
                        <td>$2,379</td>
                        <td>$3,450</td>
                        <td>$3,849</td>
                        <td>$4,656</td>
                    </tr>
                    <tr>
                        <th scope="row"></th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th scope="row">Resource Fees</th>
                        <td>$993</td>
                        <td>$1,191</td>
                        <td>$1,191</td>
                        <td>$885</td>
                    </tr>
                    <tr>
                        <th scope="row"></th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th scope="row">Total</th>
                        <td>$3,372</td>
                        <td>$4,641</td>
                        <td>$5,040</td>
                        <td>$5,541</td>
                    </tr>
                    </tbody>
                </table>


                <PageTitle title="Building Levy" variant="h3"/>
                <p>
                    Each family is required to pay a building levy of $450 this is used to
                    help pay for the maintenance and improvement of college buildings.
                </p>

                <PageTitle title="Resource Fees" variant="h3"/>
                <p>
                    This fee provides for student learning resources and includes copyright licences, Information
                    Technology requirements, teaching materials and library resources.
                </p>


                <PageTitle title="DISCOUNTS" variant="h3"/>
                <p>
                    Sibling discounts are given on tuition fees only, at the following rates.
                </p>

                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Sibling</th>
                        <th scope="col">Sibling Discount
                            Rate</th>

                    </tr>
                    </thead>
                <tbody>
                <tr>
                    <td>2nd child</td>
                    <td>10%</td>
                </tr>
                <tr>
                    <td>3rd child</td>
                    <td>40%</td>
                </tr>
                <tr>
                    <td>4th child and more</td>
                    <td>100%</td>
                </tr>
                </tbody>
            </table>

                <PageTitle title="Additional Fees" variant="h3"/>
                <p>
                    Excursions, camps and swimming programs may be organised through the year, which
                    will be in addition to the above fees.
                </p>
                <p>
                    Students participating in the following courses will incur the indicated fees.
                </p>
                <p>TAFE (TVET) - $850</p>
                <p>Hospitality (TVET) - $370</p>
                <p>Food Technology (Yrs 9 - 10) - $120</p>
                <p>Woodwork (Yrs 9 - 10) - $120</p>
                <p>STEM (Yrs 9 - 10) - $90</p>

                <PageTitle title="Excursion Levy" variant="h3"/>
                <p>
                    The Excursion Levy covers the cost of swimming programs, carnivals, sport events,
                    excursions, camps and incursions that have been planned for each year group.
                </p>
                <h2>
                    Primary Excursion Levy and Student Online Resource Fees
                </h2>

                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Kindy</th>
                            <th scope="col">Year 1</th>
                            <th scope="col">Year 2</th>
                            <th scope="col">Year 3</th>
                            <th scope="col">Year 4</th>
                            <th scope="col">Year 5</th>
                            <th scope="col">Year 6</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row">Excursions,
                                Camp &
                                Incursions</th>
                            <td>$160.00</td>
                            <td>$160.00</td>
                            <td>$145.00</td>
                            <td>$210.00</td>
                            <td>$210.00</td>
                            <td>$210.00</td>
                            <td>$410.00</td>
                        </tr>
                        <tr>
                            <th scope="row">Gala and
                                Interschool
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>$80.00</td>
                            <td>$80.00</td>
                            <td>$140.00</td>
                            <td>$140.00</td>
                        </tr>
                        <tr>
                            <th scope="row">Carnivals –
                                Swimming and
                                Athletics
                            </th>
                            <td>$235.00</td>
                            <td>$235.00</td>
                            <td>$235.00</td>
                            <td>$235.00</td>
                            <td>$235.00</td>
                            <td>$235.00</td>
                            <td>$235.00</td>
                        </tr>
                        <tr>
                            <th scope="row">Total Excursion Levy
                            </th>
                            <td>$395.00</td>
                            <td>$395.00</td>
                            <td>$380.00</td>
                            <td>$525.00</td>
                            <td>$525.00</td>
                            <td>$585.00</td>
                            <td>$785.00</td>
                        </tr>
                        <tr>
                            <th scope="row">Maths Program –
                            Matific/Maths Pathway</th>
                            <td>$15.00</td>
                            <td>$15.00</td>
                            <td>$15.00</td>
                            <td>$15.00</td>
                            <td>$15.00</td>
                            <td>$15.00</td>
                            <td>$80.00</td>
                        </tr>
                        <tr>
                            <th scope="row">Literacy-Reading
                                Eggs/Literacy Pro
                            </th>
                            <td>$30.00</td>
                            <td>$30.00</td>
                            <td>$30.00</td>
                            <td>$30.00</td>
                            <td>$30.00</td>
                            <td>$30.00</td>
                            <td>$30.00</td>
                        </tr>
                        <tr>
                            <th scope="row">Literacy - Soundwaves
                            </th>
                            <td>$10.00</td>
                            <td>$10.00</td>
                             <td>$10.00</td>
                             <td>$10.00</td>
                             <td>$10.00</td>
                             <td>$10.00</td>
                             <td>$10.00</td>
                        </tr>
                        <tr>
                            <th scope="row">Total Online Resources
                            </th>
                            <td>$55.00</td>
                            <td>$55.00</td>
                            <td>$55.00</td>
                            <td>$55.00</td>
                            <td>$55.00</td>
                            <td>$55.00</td>
                            <td>$120.00</td>
                        </tr>
                        </tbody>
                    </table>

                <h2>
                    Secondary Excursion Levy and Student Online Resource Fees
                </h2>


                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Year 7</th>
                        <th scope="col">Year 8</th>
                        <th scope="col">Year 9</th>
                        <th scope="col">Year 10</th>
                        <th scope="col">Year 11</th>
                        <th scope="col">Year 12</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th scope="row">Carnivals – Swimming Program
                            and Athletics</th>
                        <td>$150.00</td>
                        <td>$150.00</td>
                        <td>$150.00</td>
                        <td>$30.00</td>
                        <td>$30.00</td>
                        <td>$30.00</td>
                    </tr>
                    <tr>
                        <th scope="row">Excursions, Camp & Incursions
                        </th>
                        <td>$553.00</td>
                        <td>$176.00</td>
                        <td>$381.00</td>
                        <td>$163.00</td>
                        <td>$582.00</td>
                        <td>$329.00</td>
                    </tr>

                    <tr>
                        <th scope="row">Total Excursion Levy
                        </th>
                        <td>$703.00</td>
                        <td>$326.00</td>
                        <td>$531.00</td>
                        <td>$193.00</td>
                        <td>$612.00</td>
                        <td>$359.00</td>
                    </tr>
                    <tr>
                        <th scope="row">Maths – MathsOnline y</th>
                        <td>$25.00</td>
                        <td>$25.00</td>
                        <td>$25.00</td>
                        <td>$25.00</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th scope="row">Languages – Education Perfect
                        </th>
                        <td>$40.00</td>
                        <td></td>
                        <td>$40.00</td>
                        <td>$40.00</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th scope="row">All subjects - Atomi
                        </th>
                        <td>$81.00</td>
                        <td>$81.00</td>
                        <td>$81.00</td>
                        <td>$81.00</td>
                        <td>$81.00</td>
                        <td>$81.00</td>
                    </tr>
                    <tr>
                        <th scope="row">Total Online Resources
                        </th>
                        <td>$146.00</td>
                        <td>$106.00</td>
                        <td>$146.00</td>
                        <td>$146.00</td>
                        <td>$81.00</td>
                        <td>$81.00</td>
                    </tr>
                    </tbody>
                </table>


                <PageTitle title="Year 11 & 12 Subject Excursions" variant="h3"/>
                <p>
                    Subjects with subject specific excursions in 2025:
                </p>
                <p>
                    Year 11 - Music, English, PDHPE, Investigating Science, Biology, Physics
                </p>
                <p>
                    Year 12 - Music and PDHPE
                </p>


                <PageTitle title="After School Care" variant="h3"/>
                <p>
                    St Maroun’s College offers After School Care for Primary Students. The cost is $40.00
                    per child, per session.
                </p>

                <PageTitle title="Fee Accounts" variant="h3"/>
                <p>
                    Accounts are billed three times a year, at the beginning of term 1, 2 and 3. Payments
                    can be made weekly, fortnightly, monthly, per term or annually.
                </p>
                <p>
                    College fees can be paid using BPAY, Cheque, Credit Card or by Standing Authority
                    (Credit Card).
                </p>

            </div>

            <SideBar items={EnrolmentSidebarLink} title="Enrolments"/>
        </ContentWrapper>

        <img
            src={enrolmentBottomImage}
            className="img-fluid justify-content-center "
            height={600}
            alt="..."
        />
    </div>
);

export default ScheduleOfFeesContent;
